import React, { useEffect, useState } from 'react'
import { introModul, tripModul } from '../Moduls'
import sanityClient from '../../client'
import imageUrlBuilder from '@sanity/image-url'
import useWindowDimensions from '../../Hooks/useDimensions'
import { Link } from 'react-router-dom'

export default function Home() {
  const [doc, setDocData] = useState(null)
  const [trips, setTrips] = useState([])

  const builder = imageUrlBuilder(sanityClient)
  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "page"]{
            image,
            title,
            permitTitle,
            permitBody,
            aboutTitle,
            aboutBody
          }`
      )
      .then((data) => setDocData(data[0]))
      .catch(console.error)
  }, [])

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "trip"]{
            image,
            title,
            subtitle,
            slug,
            itineraries,
            soldout,
            lastcall,
            coverImage
          }`
      )
      .then((data) => setTrips(data))
      .catch(console.error)
  }, [])

  const passedTrips = trips.filter((t) => new Date(t.itineraries[0].date) < new Date()).sort((a,b) => {
      const bDate = new Date(b.itineraries[0].date).getTime();
      const aDate = new Date(a.itineraries[0].date).getTime();
      return bDate - aDate;
    
  })
  const upcomingTrips = trips.filter((t) => new Date(t.itineraries[0].date) >= new Date()).sort((a,b) => {
      const bDate = new Date(b.itineraries[0].date).getTime();
      const aDate = new Date(a.itineraries[0].date).getTime();
      return bDate - aDate;
  })

  const { width } = useWindowDimensions()

  console.log(upcomingTrips);
  const sortedTrips = upcomingTrips.sort((a, b) => {
    const aDate = new Date(a.itineraries[0].date).getTime();
    const bDate = new Date(b.itineraries[0].date).getTime();
    return aDate - bDate;
  });
  console.log(sortedTrips);
  return (
    doc && (
      <>
        <section className="intro">
          <img
            src={urlFor(doc.image)}
            alt=""
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: 'calc(100% - 200px)',
              maxHeight: width > 700 ? '700px' : '500px',
              zIndex: 0,
              objectFit: 'cover',
            }}
          />
          
            <div className="auto__container" style={{zIndex: 10}}>
            <div className="trip__inner">
              <div className="trip__inner-header white">
                <h3 className="uniq">Væntanlegar ferðir</h3>
                <h6>Skoðaðu væntanlegar ferðir</h6>
              </div>
              <div className="trip__inner-row">
                {sortedTrips.slice(0, 4).map((item, index) => {
                  return (
                    <a href={`ferd/${item.slug}`} className="tripItem" key={index}>
                      <div className="tripItem__image">
                        <img src={urlFor(item.coverImage)} alt="trip" />
                      </div>
                      <h3>
                        {item.title}
                        <br />
                        <p style={{ fontSize: '22px' }}>{item.subtitle}</p>
                      </h3>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="auto__container">
            <div className="intro__inner">
              <div className="intro__inner-row">
                {sortedTrips.slice(4).map((item, index) => {
                  return (
                    <a href={`ferd/${item.slug}`} className="introItem" key={index}>
                      <div className="introItem__image">
                        <img src={urlFor(item.coverImage)} alt="trip" />
                      </div>
                      <div className="introItem__info">
                        <h6>{item.title}</h6>
                        <h7>{item.subtitle}</h7>
                        {item.soldout ? (
                          <b style={{ display: 'block' }} className="uniq">
                            Uppseld
                          </b>
                        ) : item.lastcall ? (
                          <b style={{ display: 'block' }} className="uniq">
                            Fáein pláss laus
                          </b>
                        ) : (
                          ''
                        )}
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="trip">
          <div className="auto__container">
            <div className="trip__inner">
              <div className="trip__inner-header">
                <h3 className="uniq">Fyrri ferðir</h3>
                <h6>Skoðaðu síðustu ferðinar sem við höfum farið.</h6>
              </div>
              <div className="intro__inner-row">
                {passedTrips.slice(0, 4).map((item, index) => {
                  return (
                    <a href={`ferd/${item.slug}`} className="introItem" key={index}>
                      <div className="introItem__image">
                        <img src={urlFor(item.coverImage)} alt="trip" />
                      </div>
                      <div className="introItem__info">
                        <h6>{item.title}</h6>
                        <h7>{item.subtitle}</h7>
                      </div>
                    </a>
                  )
                })}
              </div>
              <br/>
                <Link to={'/trips'} style={{width: "100%", display: "flex", justifyContent: "center"}}><button className="button primary">Skoða eldri ferðir</button></Link>
              
              
            </div>
          </div>
        </section>
        <section className="about">
          <div className="auto__container">
            <div className="about__inner">
              <h3 className="uniq">Um Ævintýraferðir Örnólfs</h3>
              <div className="about__inner-row">
                <div className="about__inner-content">
                  <h6>{doc.permitTitle}</h6>
                  <p className="gray">{doc.permitBody}</p>
                </div>
                <div className="about__inner-image">
                  <img src="images/about/about.png" alt="about" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="ceo">
          <div className="auto__container">
            <div className="ceo__inner">
              <h3 className="uniq">{doc.aboutTitle}</h3>
              <div className="ceo__inner-row">
                <div className="ceo__inner-shape">
                  <div className="ceo__inner-image">
                    <img src="images/about/about-3.jpg" alt="ceo" />
                  </div>
                </div>
                <div className="ceo__inner-content">
                  <p>{doc.aboutBody}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  )
}
