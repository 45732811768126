import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header">
      <div className="auto__container">
        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" style={{height: "60px", maxWidth: "80%"}}/>
            
          </Link>
        </div>
      </div>
    </header>
  );
}
